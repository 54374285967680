<!--  -->
<template>
  <div class=''>
    <div class="pageTop">小屋体检情况</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <el-form ref="form" :model="form" label-width="720px">
      <el-row>
        <div class="block">
          <!--      <span class="demonstration">使用 value-format</span>-->
          <!--      <div class="demonstration">值：{{ value1 }}</div>-->
          <el-date-picker v-model="form.date1" type="date" placeholder="选择开始日期" 
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <div class="inLine"> 至 </div>
          <el-date-picker v-model="form.date2" type="date" placeholder="选择结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-button type="primary" @click="getEntUserDetailInfo()">查询</el-button>
        </div>


      </el-row>
      <div class="resultTitle">{{ form.date1 }}到{{ form.date2 }}期间小屋的体检情况如下</div>
      <div class="resultAll">
        小屋该日检查人数为{{ resultValue1 }}人，其中心电检查{{ resultValue2 }}人，血压检查{{ resultValue3 }}人，血氧检查{{ resultValue4 }}人，体脂检查{{
          resultValue5 }}人。
      </div>
    </el-form>

    <el-table :data="tableData.filter(data => !search || data.uname.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%" border :header-cell-style="{ 'text-align': 'center' }">

      <el-table-column type="index" width="40"></el-table-column>


      <el-table-column label="用户姓名" prop="uname" width="180">
        <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.uname">
            </el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-button @click="updateRealName(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template>
      </el-table-column>

      <el-table-column label="1.心电" prop="ecg" >
        <el-table-column label="静态单导联"  width="100">
          <template slot-scope="scope">
            <el-link
              :type="scope.row.personal_static_ecg === '0' || scope.row.personal_static_ecg === 0 ? 'info' : 'primary'"
              @click="getECG(scope.row.uid)">{{ scope.row.personal_static_ecg }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="动态单导联"  width="100">
          <template slot-scope="scope">
            <el-link
              :type="scope.row.personal_dynamic_ecg === '0' || scope.row.personal_dynamic_ecg === 0 ? 'info' : 'primary'"
              @click="getECGHolter(scope.row.uid)">{{ scope.row.personal_dynamic_ecg }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="静态八导联"  width="100">
          <template slot-scope="scope">
            <el-link
              :type="scope.row.personal_static_ecg_8 === '0' || scope.row.personal_static_ecg_8 === 0 ? 'info' : 'primary'"
              @click="get8ECG(scope.row.uid)">{{ scope.row.personal_static_ecg_8 }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="动态八导联"  width="100">
          <template slot-scope="scope">
            <el-link
              :type="scope.row.personal_dynamic_ecg_8 === '0' || scope.row.personal_dynamic_ecg_8 === 0 ? 'info' : 'primary'"
              @click="get8ECGHolter(scope.row.uid)">{{ scope.row.personal_dynamic_ecg_8 }}</el-link>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="2.血压" prop="blood_pressure" width="80">
        <template slot-scope="scope">
          <el-link :type="scope.row.personal_blood_pressure === '0' || scope.row.personal_blood_pressure === 0 ? 'info' : 'primary'"
            @click="getBloodPressure(scope.row.uid)">{{ scope.row.personal_blood_pressure }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="3.尿常规" prop="urinalysis" width="80">
        <template slot-scope="scope">
          <el-link :type="scope.row.personal_urine === '0' || scope.row.personal_urine === 0 ? 'info' : 'primary'"
            @click="getUrine(scope.row.uid)">{{
              scope.row.personal_urine }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="4.血氧" prop="blood_oxygen" width="80">
        <template slot-scope="scope">
          <el-link :type="scope.row.personal_blood_oxygen === '0' || scope.row.personal_blood_oxygen === 0 ? 'info' : 'primary'"
            @click="getBloodOxygen(scope.row.uid)">{{ scope.row.personal_blood_oxygen }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="5.血糖" prop="blood_glucose" width="80">
        <template slot-scope="scope">
          <el-link :type="scope.row.personal_blood_sugar === '0' || scope.row.personal_blood_sugar === 0 ? 'info' : 'primary'"
            @click="getBodyGlucose(scope.row.uid)">{{ scope.row.personal_blood_sugar }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="6.血脂" prop="blood_lipid" width="80">
        <template slot-scope="scope">
          <el-link :type="scope.row.personal_blood_lipids === '0' || scope.row.personal_blood_lipids === 0 ? 'info' : 'primary'"
            @click="getBloodFat(scope.row.uid)">{{ scope.row.personal_blood_lipids }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="7.三合一" width="80">
        <template slot-scope="scope">
            <el-link
              :type="scope.row.personal_three_in_one === '0' || scope.row.personal_three_in_one === 0 ? 'info' : 'primary'"
              @click="getThreeInOne(scope.row.uid)">{{ scope.row.personal_three_in_one }}</el-link>
          </template>
        <!-- <el-table-column label="血糖" prop="threeInOne.blood_glucose" width="80">
          <template slot-scope="scope">
            <el-link
              :type="scope.row.threeInOne.blood_glucose === '0' || scope.row.threeInOne.blood_glucose === 0 ? 'info' : 'primary'"
              @click="getThreeInOne(e)">{{ scope.row.threeInOne.blood_glucose }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="胆固醇" prop="threeInOne.cholesterol" width="80"> 
          <template slot-scope="scope">
            <el-link
              :type="scope.row.threeInOne.cholesterol === '0' || scope.row.threeInOne.cholesterol === 0 ? 'info' : 'primary'"
              @click="getThreeInOne(e)">{{ scope.row.threeInOne.cholesterol }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="尿酸" prop="threeInOne.uric_acid" width="80"> 
          <template slot-scope="scope">
            <el-link
              :type="scope.row.threeInOne.uric_acid === '0' || scope.row.threeInOne.uric_acid === 0 ? 'info' : 'primary'"
              @click="getThreeInOne(e)">{{ scope.row.threeInOne.uric_acid }}</el-link>
          </template>
        </el-table-column> -->
      </el-table-column>

      <el-table-column label="8.体脂" prop="body_fat" width="80">
        <template slot-scope="scope">
          <el-link :type="scope.row.personal_bodyfat === '0' || scope.row.personal_bodyfat === 0 ? 'info' : 'primary'"
            @click="getBodyFat(scope.row.uid)">{{ scope.row.personal_bodyfat }}</el-link>
        </template>
      </el-table-column>


      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.remarks">
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="updateRemark(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot:default="scope">
          <el-link type="primary" @click="psInfo(scope.row)">{{ '个人信息表' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-link type="primary" @click="">{{ '上报' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-button type="danger" @click="handleDelete(scope.$index, scope.row)" size="mini"><i
              class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>

    </el-table>
    <div v-if="psInfoVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="psInfoVisible">
        <ps_information :userId="this.uidCurrent" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ps_information from '../../personal_module/ps_info/ps_information.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    ps_information
  },
  data() {
    //这里存放数据
    return {
      psInfoVisible: false,
      form: {
        date1: '',
        date2: '',
      },
      resultValue1:"",
      resultValue2:"",
      resultValue3:"",
      resultValue4:"",
      resultValue5:"",
      uidCurrent:"",
      tableData: [
      // {
      //   uid: '',
      //   personal_dynamic_ecg: '',
      //   personal_blood_pressure: '',
      //   personal_urine: '',
      //   uname: '',
      //   personal_static_ecg_8: '',
      //   personal_blood_lipids: '',
      //   personal_dynamic_ecg_8: '',
      //   personal_dynamic_bp: '',
      //   personal_static_ecg: '',
      //   personal_blood_oxygen: '',
      //   personal_bodyfat: '',
      //   personal_three_in_one: '',
      //   personal_blood_sugar:'',
      //   }
    ]

    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取个人信息表
    psInfo(e) {
      this.psInfoVisible = true
      this.uidCurrent = e.uid

    },
    TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y+'-'+MM + '-' + d
        },
    // 获取七天的起止日期
    getToday() {
            var today = new Date();
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 7);
            this.year_today = today.getFullYear()
            this.year_formerday = formerday.getFullYear()
            this.form.date2 = this.TimeProcess(today)
            this.form.date1 = this.TimeProcess(formerday)
            console.log(this.form.date1)
            console.log(this.form.date2)

        },
    // 检测项目跳转
    getECG(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoJump',
        query: {
          uid: uid,
        }
      })
    },
    getECGHolter(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        }
      })
    },

    getBloodFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodFatJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyGlucose(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyGlucoseJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodOxygen(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodOxygenJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodPressure(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodPressureJump',
        query: {
          uid: uid,
        }
      })
    },
    getBaby(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBabyJump',
        query: {
          uid: uid,
        }
      })
    },
    getBio(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBioJump',
        query: {
          uid: uid,
        }
      })
    },
    getTem(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryTemJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyFatJump',
        query: {
          uid: uid,
        }
      })
    },
    getThreeInOne(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryThreeInOneJump',
        query: {
          uid: uid,
        }
      })
    },
    getUrine(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryUrineJump',
        query: {
          uid: uid,
        }
      })
    },
    get8ECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightHolterJump',
        query: {
          uid: e,
        }
      })
    },
    getEntUserDetailInfo() {
      this.$http({
        url: this.$http.adornUrl('/enterprise/enterpriseuserinfo/getEntUserDetailInfo'),
        method: 'get',
        params: this.$http.adornParams({
          eid:this.$store.state.health_station.eid, startTime:this.form.date1,endTime:this.form.date2
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.tableData = data.detailInfo
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getToday()
    this.getEntUserDetailInfo()

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.pageTop {
  font-size: 35px;
}

.dateSelect {
  width: 500px;
}

.inLine {
  font-size: 15px;
  display: inline-block;
  word-spacing: 15px;
}

.resultTitle {
  font-size: 35px;
  margin-top: 25px;
}

.resultAll {
  font-size: 25px;
  margin-top: 25px;
}

.resultTable {
  margin-top: 25px;
}</style>
